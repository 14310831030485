import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/docs-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}>{`Overview`}<a parentName="h2" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Rules allow you to control the `}<a parentName="p" {...{
        "href": "/docs/actions/overview"
      }}>{`Actions`}</a>{` that should be taken based on a set of
`}<a parentName="p" {...{
        "href": "/docs/conditions/overview"
      }}>{`Conditions`}</a>{` that have passed a truth test.`}</p>
    <p>{`Rules execute all of their Actions when all of their specified Conditions are true. Conditions may reference Events,
Sessions, Profiles, Sources, and Segments, while Actions may utilize Connections when interacting with external systems.`}</p>
    <p>{`For example, suppose you fire an event `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Form Completed`}</code>{` after a visitor to your website completes a form. You may define
a Rule that depends on a Condition where `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`event`}</code>{` must equal `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Form Completed`}</code>{`, and which fires an Action that sends a
notification email.`}</p>
    <h2 {...{
      "id": "how-rules-are-evaluated-and-executed",
      "style": {
        "position": "relative"
      }
    }}>{`How Rules are Evaluated and Executed`}<a parentName="h2" {...{
        "href": "#how-rules-are-evaluated-and-executed",
        "aria-label": "how rules are evaluated and executed permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Rules are retrieved based on an inbound Event's information and are processed via a structured lifecycle:`}</p>
    <ol>
      <li parentName="ol">{`A client (Javascript library, HTTP request) sends an Event to Firstparty`}</li>
      <li parentName="ol">{`Firstparty looks up the Source based on that Event's `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`write_key`}</code>{` value`}</li>
      <li parentName="ol">{`Firstparty saves the Event, Session, and Profile, and sends a response to the Client`}</li>
      <li parentName="ol">{`Firstparty now retrieves all Rules enabled for that Source, evaluates the Conditions, and executes the
appropriate Actions.`}</li>
      <li parentName="ol">{`The Event, Session, and Profile are saved once more.`}</li>
    </ol>
    <p>{`Rules are executed `}<strong parentName="p">{`after`}</strong>{` a response is sent to the client. This is
appropriate when used in conjunction with the JavaScript Source, since the client browser making a request won't
have to wait for the Rule to evaluate - it will just receive a super fast response.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      